import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { withTheme } from "styled-components"

import SEO from "../components/globals/seo"
import Layout from "../components/containers/layout"
import Section from "../components/containers/section"
import Container from "../components/containers/container"
import Heading from "../components/typography/heading"
import FaqBannerSmall from "../components/banners/faqBannerSmall"
import Link from "../components/links/link"
import Breadcrumb from "../components/globals/breadcrumb"
import Video from "../components/video/video"

const Content = styled.div`
  a {
    font-family: ${p => p.theme.headingFont};
    transition: ${p => p.theme.transitionDefault};
  }
  a:hover {
    color: ${p => p.theme.green};
  }

  div[data-oembed-type="video"] {
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  div,
  ul,
  p {
    margin-bottom: 50px;
    line-height: 32px;
  }
  h2,
  h3 {
    margin-bottom: 24px;
    color: ${p => p.theme.blueDark};
    line-height: 32px;
  }

  h2 {
    font-size: 30px;
    font-family: ${p => p.theme.headingFont};
  }
  h3 {
    font-size: 28px;
    font-family: ${p => p.theme.baseFont};
    font-weight: normal;
  }

  p {
    color: ${p => p.theme.greyDark};
    font-size: ${p => p.theme.baseFontSizeTabMob};
  }

  li {
    color: ${p => p.theme.greyDark};
    list-style-type: disc;
    margin-left: 30px;
    margin-bottom: 12px;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    div,
    ul,
    p {
      line-height: 38px;
    }
    h2,
    h3 {
      margin-bottom: 10px;
    }
  }
  @media (min-width: ${p => p.theme.screenXL}) {
    p {
      font-size: ${p => p.theme.baseFontSizeDesktop};
    }
    h2,
    h3 {
      margin-bottom: 20px;
    }
  }
`

const FaqQuestionPage = ({ data, theme }) => {
  // Grabbing data from Prismic
  const {
    uid,
    data: {
      category,
      title,
      faq_answer: { html: answer },
      yt_video_id,
    },
  } = data.allPrismicFaq.edges[0].node
  const categoryClean = category.replace(`-`, ` `)

  return (
    <Layout navbarPadOnly>
      <SEO
        title="FAQ: question and answer"
        description="A frequently asked question about TopTekkers"
      />

      <FaqBannerSmall />

      <Section pt={[60, null, 72]}>
        <Container maxWidth="992px">
          <Breadcrumb>
            <Link to="/faq/">FAQ</Link>
            <Link to={`/faq/${category}`}>{categoryClean}</Link>
          </Breadcrumb>

          <Heading mb="36px" maxWidth="none" capitalize>
            {title.text}
          </Heading>
          <Content dangerouslySetInnerHTML={{ __html: answer }} />

          {yt_video_id && (
            <Video>
              <iframe
                title="TopTekkers FAQ related video"
                src={`https://www.youtube.com/embed/${yt_video_id}`}
                width="100%"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </Video>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($currentUID: String!) {
    allPrismicFaq(filter: { uid: { eq: $currentUID } }) {
      edges {
        node {
          data {
            category
            title {
              text
            }
            faq_answer {
              html
            }
            yt_video_id
          }
        }
      }
    }
  }
`

FaqQuestionPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(FaqQuestionPage)
