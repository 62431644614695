import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ children, maxWidth }) => {
  return <Root maxWidth={maxWidth}>{children}</Root>;
};

Video.propTypes = {
  children: PropTypes.object,
  maxWidth: PropTypes.string,
};

export default Video;
